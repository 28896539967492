import { Box, Flex, Heading, IconButton, Button, Text } from '@chakra-ui/react';
import { useState, useRef } from 'react';
import { FaMicrophone, FaStop } from 'react-icons/fa';

interface AudioRecorderProps {
    title: string;
    onSubmit: (audioURL: string) => void;
    disabled: boolean;
}

function AudioRecorder({ title, onSubmit, disabled }: AudioRecorderProps) {
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [audioURL, setAudioURL] = useState<string>('');
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunks = useRef<Array<Blob>>([]);

    const startRecording = async () => {
        setAudioURL('');
        setIsRecording(true);
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunks.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            const url = URL.createObjectURL(audioBlob);
            setAudioURL(url);
            audioChunks.current = [];
        };

        mediaRecorderRef.current.start();
    };

    const stopRecording = () => {
        setIsRecording(false);
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
    };

    return (
        <Flex direction="column" align="center" justify="center" mt={8} p={6} borderRadius="md" bg="gray.50">
            <Box textAlign="center" mb={4}>
                <Heading as='h4'>
                    {title}
                </Heading>
                <Text color="gray.600">
                    {isRecording ? 'Recording in progress...' : 'Press the button to start recording'}
                </Text>
            </Box>
            <IconButton
                isDisabled={disabled}
                _hover={{ transform: 'scale(1.1)' }}
                onClick={isRecording ? stopRecording : startRecording}
                icon={isRecording ? <FaStop /> : <FaMicrophone />}
                colorScheme={isRecording ? 'red' : 'green'}
                size="lg"
                aria-label="Record"
                isRound
                mb={4}
            />
            {audioURL && (
                <Flex mt={4} p={4} gap={4} direction='column' >
                    <Box>
                        <Text fontWeight='bold'>
                            Preview
                        </Text>
                        <audio controls>
                            <source src={audioURL} type="audio/wav" />
                            Your browser does not support the audio element.
                        </audio>
                    </Box>
                    <Button w='full' colorScheme='brand' onClick={() => onSubmit(audioURL)} isDisabled={disabled}>
                        Submit
                    </Button>
                </Flex>
            )}
            <Flex gap={2} mt='2' align='center' justify='end' w='full' fontSize='sm'>
                <Text fontStyle='italic'>Powered by</Text>
                <Heading as='h4' size='md' >Plurally
                </Heading>
            </Flex>
        </Flex>
    );
}

export default AudioRecorder;
